.dateRangePickerModalOverlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #fff, $alpha: 0.7);
    z-index: 99999;

    .dateRangePickerModal {
        border-radius: 5px;
        background: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        box-shadow: 4px 6px 6px 2px rgba(0, 0, 0, 0.6);

        .closeModalBtn {
            margin: 10px auto;
            width: 94%;
            align-self: center;
            background: #0054a6;
            color: #fff;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;
            transition: background 200ms ease-in-out;

            &:hover {
                background: lighten($color: #0054a6, $amount: 5%);
            }
        }
    }
}
