.scheduleControlContent {
  &__header {
    padding: 8px;

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 38px;
      padding: 10px 30px;
      background-color: #fdffc2;
      color: #0054A6;
      border-radius: 4px;
      text-align: center;
      font-weight: bold;
      font-size: 15px;
      border: 1px solid #edea42;
      margin-bottom: 18px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      >h1 {
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #2a333e;
        margin-bottom: 8px;
        text-transform: uppercase;
      }

      .deactivateBtn {
        align-self: center;
        font-weight: bold;
        font-size: 14px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 200ms ease-in-out;
        border: 1px solid #ed1d24;
        background-color: #fff;
        color: #ed1d24;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: rgba(#ed1d24, 0.2);
        }

        >svg {
          width: 18px;
          height: 18px;
          fill: #ed1d24;
        }
      }

      .reactivateBtn {
        align-self: center;
        font-weight: bold;
        font-size: 14px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 200ms ease-in-out;
        border: 1px solid #0054A6;
        background-color: #fff;
        color: #0054A6;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: rgba(#0054A6, 0.2);
        }

        >svg {
          width: 18px;
          height: 18px;
          fill: #0054A6;
        }
      }

      >h4 {
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #404f61;
      }
    }
  }
}