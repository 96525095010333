.storeServicesLinked {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 0 8px;

  .servicesGridCell {
    display: flex;
    align-items: center;
    height: 34px;
  }

  .p-datatable-wrapper {
    .p-cell-editing, .p-filter-column {
      input, .p-inputwrapper {
        width: 100%;
      }
    }
  }
  

  .dx-datagrid-header-panel {
    padding: 0 8px !important;
  }
}
