.resqueduleModalOverlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #fff, $alpha: 0.7);
    z-index: 1000;

    .resqueduleModal {
        border-radius: 5px;
        background: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        width: 340px;
        height: auto;
        box-shadow: 4px 6px 6px 2px rgba(0, 0, 0, 0.6);

        &__header {
            background-color: rgb(239, 242, 247);
            font-size: 18px;
            width: 100%;
            margin: 0 -8px;
            line-break: none;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: bold;
            padding: 8px;
        }

        &__stepper {
            width: 100%;
            padding: 8px;
        }

        &__buttons {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 8px;

            .cancelBtn {
                flex: 1;
                border: 1px solid #ddd;
                background: #fff;
                padding: 10px 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                border-radius: 5px;
                cursor: pointer;
                transition: background 200ms ease-in-out;

                &:hover {
                    background: lighten($color: #ddd, $amount: 5%);
                }
            }

            .proceedBtn {
                flex: 1;
                background: #0054a6;
                color: #fff;
                padding: 10px 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                cursor: pointer;
                transition: background 200ms ease-in-out;

                &:hover {
                    background: lighten($color: #0054a6, $amount: 5%);
                }
            }
        }
    }
}

button:disabled,
button[disabled] {
    border: 1px solid #ccc !important;
    background: #ddd !important;
    text-shadow: rgba(0, 0, 0, 0.1) -1px 0, rgba(0, 0, 0, 0.1) 0 -1px, rgba(255, 255, 255, 0.1) 1px 0,
        rgba(255, 255, 255, 0.1) 0 1px, rgba(0, 0, 0, 0.1) -1px -1px, rgba(255, 255, 255, 0.1) 1px 1px;
    color: #555 !important;

    &:hover {
        background: lighten(#ddd, 4%) !important;
    }
}
