.header {
  display: flex;
  flex-direction: column;
  padding: 8px;

  p {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #2a333e;
    margin-bottom: 8px;
  }

  span {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #404f61;
  }
}

.periodSelection {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__button {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
    cursor: pointer;
    transition: background-color ease-in-out 0.3s;

    &.selected {
      background-color: #f2f2f2;
      border-color: #0054a5;
    }

    &:hover {
      background-color: #f2f2f2;
      border-color: #0054a5;
    }

    &__title {
      font-weight: bold;
      font-size: 18px;
    }

    &__interval {
      font-size: 12px;
    }

    &__count {
      font-size: 12px;
    }

  }

}

.schedulesGrid {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  &__exportButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__legendAndBtns {
      width: auto;
      margin: 8px;

      >span{
        font-size: 12px;
      }

      &__buttons {
        display: flex;
        margin-top: 4px;
        align-items: center;
        justify-content: flex-end;

        >button{
          align-self: center;
          border: 1px solid #ddd;
          background: #fff;
          padding: 5px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;
          transition: background 200ms ease-in-out;

          &:hover {
            background: lighten($color: #ddd, $amount: 5%);
          }
        }

      }
    }
  }

  .schedulesGridCell {
    display: flex;
    align-items: center;
    height: 34px;
  }

  .dx-datagrid-header-panel {
    padding: 0 8px !important;
  }
}
