.partnerPageContainer {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    min-height: calc(100vh - 24px);
    padding: 82px 20px 20px 20px;
    flex: 1;
    max-width: 1366px;

    &__sideBar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        border: 1px solid #ddd;
        border-radius: 5px;
        height: auto;
        margin-bottom: 15px;
        margin-right: 8px;
    }

    &__content {
        height: auto;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;
    }

    button:disabled,
    button[disabled] {
        border: 1px solid #ccc !important;
        background: #ddd !important;
        text-shadow: rgba(0, 0, 0, 0.1) -1px 0, rgba(0, 0, 0, 0.1) 0 -1px, rgba(255, 255, 255, 0.1) 1px 0,
            rgba(255, 255, 255, 0.1) 0 1px, rgba(0, 0, 0, 0.1) -1px -1px, rgba(255, 255, 255, 0.1) 1px 1px;
        color: #555 !important;

        &:hover {
            background: lighten(#ddd, 4%) !important;
        }
    }
}
