.serviceIcons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    overflow-x: auto;

    &__service {
        margin: 0 2px;
        transition: all ease-in-out 0.3s;

        &:hover {
            filter: brightness(150%);
        }

        &__icon {
            > img {
                width: 28px;
            }
        }
    }
}
