.timePickerModalOverlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    z-index: 2000;

    .timePickerModal {
        border-radius: 5px;
        background: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        box-shadow: 4px 6px 6px 2px rgba(0, 0, 0, 0.6);
        width: 350px;
        height: auto;

        &__title {
            background-color: rgb(239, 242, 247);
            font-size: 18px;
            width: 100%;
            margin: 0 -8px;
            line-break: unset;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: bold;
            padding: 8px;
            margin-bottom: 10px;
        }

        &__pickers {
            padding: 40px 30px 15px 30px;
            width: 100%;
            flex: 1;

            &__item {
                display: flex;
                align-items: center;
                justify-content: center;

                > span {
                    font-size: 12px;
                    flex: 1 0 52px;
                }

                > div {
                    width: 100%;
                }
            }

            &__selectedTime {
                font-size: 30px;
                font-weight: bold;
                margin-top: 15px;
                width: 100%;
                text-align: center;
            }
        }

        .closeModalBtn {
            margin: 10px auto;
            width: 94%;
            align-self: center;
            background: #0054a6;
            color: #fff;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;
            transition: background 200ms ease-in-out;

            &:hover {
                background: lighten($color: #0054a6, $amount: 5%);
            }
        }
    }
}
