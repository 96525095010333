.manageServiceContent {
    &__header {
        padding: 8px;

        > h1 {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            color: #2a333e;
            margin-bottom: 8px;
            text-transform: uppercase;
        }

        > h4 {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #404f61;
        }
    }
}
