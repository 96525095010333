.loadingScreenOverlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(#0054a5, 0.95);
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: ease-in-out opacity 1s;
    -moz-transition: ease-in-out opacity 1s;
    -o-transition: ease-in-out opacity 1s;
    transition: ease-in-out opacity 1s;

    &.open {
        visibility: visible;
        opacity: 1;
    }

    &__barLoader {
        width: 100%;
        margin-top: -6px;
    }

    &__animatedLogo {
        width: 60%;
        padding: 50px;
        position: fixed;
        bottom: 35px;
        right: 35px;
    }
}
