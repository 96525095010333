.weekDay {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    margin: 12px 0;

    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #d7e2ed;
        padding: 8px;

        &__dayInfo {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-flow: column;

            &__dayName {
                font-size: 16px;
                font-weight: bold;
                margin: 0;
                padding: 0;
                text-transform: uppercase;
            }

            &__dayWorkingHours {
                font-size: 11px;
                margin-top: -3px;
                padding: 0;
            }
        }

        &__actionButtons {
            display: flex;
            align-items: center;
            justify-content: center;

            > button {
                align-self: center;
                background: #0054a6;
                font-size: 14px;
                color: #fff;
                padding: 8px 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                cursor: pointer;
                transition: background 200ms ease-in-out;

                &:hover {
                    background: lighten($color: #0054a6, $amount: 5%);
                }
            }
        }
    }

    &__periods {
        padding: 8px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 8px;
        grid-row-gap: 8px;

        @media (max-width: 961px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 450px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
